(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyListController', PatientTherapyListController);

    PatientTherapyListController.$inject = ['$log', '$scope', '$state', '$stateParams', '$uibModal', '$q', '$window', 'PatientTherapy', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'HealthFacility', 'Principal', 'Practitioner', 'CareTeam', 'Visit', 'Therapy'];

    function PatientTherapyListController($log, $scope, $state, $stateParams, $uibModal, $q, $window, PatientTherapy, ParseLinks, AlertService, paginationConstants, pagingParams, HealthFacility, Principal, Practitioner, CareTeam, Visit, Therapy) {

        const vm = this;

        vm.openPractitioner = openPractitioner;
        vm.openFacility = openFacility;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;

        vm.filters = $stateParams.filters;

        vm.statuses = ['S11_VALIDATED', 'S20_ACTIVE', 'S30_PAUSED', 'S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'];

        vm.loadAll = loadAll;
        vm.onFiltersChange = onFiltersChange;

        vm.$onInit = function () {
            Principal.identity().then(function (account) {

                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.practitioner = account.practitioner;
                if (vm.practitioner) {
                    vm.isDoctor = vm.practitioner.job === 'J10';
                    vm.isCoordination = vm.practitioner.healthFacilityType === 'COORDINATION';
                }

                if (angular.isUndefined(vm.filters.assigneeId) && vm.isCoordination) {
                    vm.filters.assigneeId = vm.practitioner.id;
                }

                loadAll();
            });

            vm.unwatchStatus = $scope.$watchCollection("vm.filters.status", function (oldValue, newValue) {
                onFiltersChange();
            });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchStatus) {
                vm.unwatchStatus();
            }
        };

        function onFiltersChange() {
            if (vm.page) {
                if (vm.page === 1) {
                    loadAll();
                } else {
                    // reset page number
                    vm.page = 1;
                    // change url
                    vm.transition();
                }
            }

        }

        function loadAll() {

            if(vm.isLoading) {
                $log.warn('Already loading, skipped.');
                return;
            }

            vm.isLoading = Date.now();

            vm.patientTherapies = null;

            if (vm.isAdmin) {
                PatientTherapy.queryDistinctFacilities({
                    "status.in": vm.filters.status,
                    "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                    "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                    "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                    "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                    "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                }, function (result) {
                    vm.healthfacilities = result;
                });
            }

            PatientTherapy.queryDistinctAssignees({
                "status.in": vm.filters.status,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId
            }, function (result) {
                vm.assignees = result;

                // Si l'utilisateur fait partie de la structure de coordination C+ on le rajoute à la liste s'il n'en fait partie
                if (vm.isCoordination) {
                    const isUserInAssignees = vm.assignees.filter((p) => p.id === vm.practitioner.id).length;
                    if (!isUserInAssignees) {
                        vm.assignees.push(vm.practitioner);
                    }
                }
            });

            PatientTherapy.queryDistinctPrescribers({
                "status.in": vm.filters.status,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined
            }, function (result) {
                vm.prescribers = result;
            });

            PatientTherapy.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "status.in": vm.filters.status,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                'assigneeId.specified': vm.filters.assigneeId === 'notAssignee' ? false : undefined,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'startDate') {
                    result.push('startDate,desc');
                }
                result.push('id,desc');
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientTherapies = data;
                vm.page = pagingParams.page;

                const promises = [];
                angular.forEach(vm.patientTherapies, patientTherapy => {

                    // récupération de la date prévue du 1er questionnaire
                    promises.push(loadPlannedDateFirstVisit(patientTherapy));

                });

                $q.all(promises).finally(() => {
                    const elapsed = Date.now() - vm.isLoading;
                    vm.isLoading = null;
                    $log.info('Loading took ' + elapsed + 'ms');
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: () => practitioner
                        }
                    });
                }
            });
        }

        function openFacility(facility) {
            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('health-facility-detail', {id: facility.id});
                    $window.open(url, 'health-facility-detail-' + facility.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                        controller: 'HealthFacilityModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            healthFacility: () => facility
                        }
                    });
                }
            });
        }

        function loadPlannedDateFirstVisit(patientTherapy) {
            // Les types de visites à prendre en compte pour la première visite
            const VALID_VISIT_TYPES = ['PRO'];

            switch (patientTherapy.status) {
            case 'S11_VALIDATED' : // parcours à démarrer

                if (!patientTherapy.therapyId || !patientTherapy.day1Date) {
                    return;
                }

                return Therapy.get({id: patientTherapy.therapyId})
                    .$promise
                    .then((therapy) => {

                        // Trie les visites par la date relative d'échéance
                        therapy.plan.visits.sort((visit1, visit2) => visit1.relativeDueDateDays - visit2.relativeDueDateDays );

                        // Trouve la première visite à prendre en compte selon le type de visite
                        const firstRelevantVisit = therapy.plan.visits.find(visit => VALID_VISIT_TYPES.includes(visit.type));

                        patientTherapy.dueDateFirstVisit = moment(patientTherapy.day1Date).add(firstRelevantVisit.relativeDueDateDays, 'days').toDate();

                        $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                    });

            case 'S20_ACTIVE' : // parcours en cours

                return Visit.query({
                    "patientTherapyId.equals": patientTherapy.id,
                    "type.in": VALID_VISIT_TYPES,
                    sort: ['dueDate', 'asc'],
                    size: 1
                }).$promise.then((visits) => {

                    if (visits && visits.length) {
                        const firstVisit = visits[0];

                        if (['TODO', 'IN_PROGRESS'].includes(firstVisit.status)) {
                            patientTherapy.dueDateFirstVisit = firstVisit.dueDate;
                        }

                    }

                    $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                });
            }

        }

    }
})();
