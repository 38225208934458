(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['_', '$filter', '$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', 'Patient', 'HealthFacility', 'Inbox', 'CareTeam'];

    function PatientController(_, $filter, $scope, $state, $stateParams, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, Patient, HealthFacility, Inbox, CareTeam) {

        const vm = this;

        vm.currentSearch = $stateParams.search;

        vm.open = open;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        $scope.$watchGroup(['vm.filters.familyName', 'vm.filters.givenName'], (newValues, oldValues) => {
            newValues[0] = newValues[0] || undefined;
            newValues[1] = newValues[1] || undefined;
            if (_.isEqual(newValues, oldValues)) {
                return;
            }
            const familyName = newValues[0];
            const givenName = newValues[1];
            if (familyName || givenName) {
                vm.filters.internalId = null;
            }
            loadAll();
        });

        $scope.$watch('vm.filters.internalId', (newVal, oldVal) => {
            if (oldVal && !newVal) {
                loadAll();
            }
        });

        vm.$onInit = () => {
            vm.filters = $stateParams.filters;

            Principal.identity().then(function (account) {

                const practitioner = account.practitioner;

                vm.hasAccessToHealthData = account.authorities.includes('PATIENT_HEALTH_READ');

                vm.isHospitalUser = practitioner.healthFacilityType === 'HOSPITAL';

                if (account.authorities.includes('ROLE_ADMIN')) {
                    vm.isAdmin = true;
                } else if (practitioner.job === 'J10') {
                    vm.isDoctor = true;
                    if (vm.isHospitalUser) {
                        if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                            vm.filters.facilityDoctorId = practitioner.id;
                        }
                        vm.forceFacilityDoctor = practitioner;
                    }
                } else if (practitioner.job === 'J60') {
                    vm.isNurse = true;
                } else if (['J21', 'J38'].includes(practitioner.job)) {
                    vm.isPharmacist = true;
                }

                if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                    vm.filters.facilityDoctorId = null;
                }
                if (angular.isUndefined(vm.filters.nurseCoordId)) {
                    vm.filters.nurseCoordId = null;
                }
                if (angular.isUndefined(vm.filters.facilityPharmacistId)) {
                    vm.filters.facilityPharmacistId = null;
                }

                loadAll();
            });

            loadUnread();
        };

        vm.$onDestroy = () => {

        };

        function loadAll() {
            if (vm.isAdmin) {
                loadDistinctFacilities();
            }
            loadDistinctFacilityDoctors();
            loadDistinctFacilityPharmacists();
            loadDistinctNurseCoords();
            loadPatient();
        }

        function loadDistinctFacilities() {
            Patient.queryDistinctFacilities({
                "sort": "healthFacility.name,asc",
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
            }, (result) => {
                vm.healthfacilities = result;
            });
        }

        function loadDistinctFacilityDoctors() {
            Patient.queryDistinctFacilityDoctors({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
            }, (result) => {
                vm.facilityDoctors = result;
                if (!result.length && vm.forceFacilityDoctor) {
                    vm.facilityDoctors.push(vm.forceFacilityDoctor);
                }
            });
        }

        function loadDistinctFacilityPharmacists() {
            Patient.queryDistinctFacilityPharmacists({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
            }, (result) => {
                vm.pharmacists = result;
            });
        }

        function loadDistinctNurseCoords() {
            Patient.queryDistinctNurseCoords({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
            }, (result) => {
                vm.nurseCoords = result;
            });
        }

        function loadPatient() {
            vm.patients = null;
            Patient.query({
                view: 'full',
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "query": vm.currentSearch || undefined,
                "firstNameStartsWith": vm.filters.givenName || undefined,
                "lastNameOrBirthNameStartsWith": vm.filters.familyName || undefined,
                "internalId": vm.filters.internalId || undefined,
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
            }, (data, headers) => {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patients = data.map(mapLastEventBy);
                vm.page = pagingParams.page;
            }, (error) =>
                AlertService.error(error.data.message));

            function sort() {
                const result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function mapLastEventBy(patient) {
                let lastEventByRoleType;
                if (patient.lastEventByRole) {
                    const lastEventByRoleRules = CareTeam.rules[patient.lastEventByRole];
                    lastEventByRoleType = lastEventByRoleRules && lastEventByRoleRules.type;
                }
                if (lastEventByRoleType === 'Practitioner') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.' + patient.lastEventByRole;
                } else if (patient.lastEventByJob) {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.practitioner.job.' + patient.lastEventByJob;
                    if (patient.lastEventByFacilityType) {
                        patient.lastEventByLabelKey2 = 'continuumplatformApp.practitioner.facilityTypeQualifier.' + patient.lastEventByFacilityType;
                    }
                } else if (patient.lastEventType === 'NEW_MESSAGE') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.PATIENT';
                }

                return patient;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                search: vm.currentSearch,
                filters: vm.filters
            });
        }

        function search() {
            if (!vm.currentSearch) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.currentSearch = null;
            vm.patient = {};
            vm.transition();
        }

        function open(id) {
            vm.isOpening = true;
            $state.go('patient-detail.quality-of-life', { id: id });
        }

        function loadUnread() {
            Inbox.getSummary().then(function (response) {
                vm.unread = {};
                angular.forEach(response.data, function (value) {
                    vm.unread[value.patientId] = {
                        countUnread: value.countUnread,
                        countUnreadImportant: value.countUnreadImportant
                    };
                });
            });
        }
    }
})();
