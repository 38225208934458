(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyStartController', PatientTherapyStartController);

    PatientTherapyStartController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', '$log', 'entity', 'PatientTherapy', 'Therapy', 'Program', 'CareTeam', 'Visit'];

    function PatientTherapyStartController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, $log, entity, PatientTherapy, Therapy, Program, CareTeam, Visit) {
        const vm = this;

        vm.preview = preview;
        vm.clear = clear;
        vm.save = save;

        let deregisterSingleOverrideWatch = null;

        vm.patientTherapy = entity;
        vm.form = {
            day1Date: entity.day1Date || new Date(),
            therapyId: entity.therapyId,
            assigneeId: entity.assignee && entity.assignee.id,
            patientEmail: null,
            singleOverride: entity.singleOverride,
        };

        vm.visitFrequencies = Visit.getFrequencies();

        const minPastDay1Date = moment().subtract(100, 'days');

        vm.maxDay1DateIso = moment().add(180, 'days').format('YYYY-MM-DD');

        vm.$onInit = () => {

            resetPlan();
            loadTherapies().then(() => {
                if (vm.form.therapyId) {
                    onTherapyIdChange(vm.form.therapyId);
                }
                vm.unWatchTherapyId = $scope.$watch("vm.form.therapyId", (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                        onTherapyIdChange(newVal);
                    }
                });
                vm.unWatchDay1Date = $scope.$watch("vm.form.day1Date", day1DateChange);
            });

            PatientTherapy.findAvailableCoordinators(vm.patientTherapy).then(coordinators => {
                vm.coordinators = coordinators;
            });
        };

        vm.$onDestroy = () => {
            vm.unWatchTherapyId();
            vm.unWatchDay1Date();
        };

        function onTherapyIdChange (newVal) {
            if (deregisterSingleOverrideWatch) {
                deregisterSingleOverrideWatch();
                deregisterSingleOverrideWatch = null;
            }

            if (newVal) {
                loadTherapy();
            } else {
                vm.therapy = null;
                resetPlan();
            }
        }

        function day1DateChange(newVal, oldVal) {
            if (vm.visits.length && newVal && newVal !== oldVal) {
                $log.debug("day1Date changed, computing planning...");
                preview();
            }
        }

        function watchSingleOverride() {
            return $scope.$watch('vm.form.singleOverride', (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    $log.debug('singleOverride changed, computing planning...');
                    if (newVal.limitDays < newVal.reminderDay) {
                        vm.form.singleOverride.reminderDay = newVal.limitDays - 1;
                    }
                    preview();
                }
            }, true);
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function loadTherapies() {
            vm.therapies = [];
            return Therapy.query({
                size: 999,
                'status.equals': 'ACTIVE',
                'sort': 'code',
            }).$promise.then(result => {
                vm.therapies = result;

                if (vm.therapies.size === 1) {
                    vm.form.therapyId = vm.therapies[0].id;
                }
                vm.loadedTherapies = true;
            });
        }

        function loadTherapy() {
            Therapy.get({
                id: vm.form.therapyId
            }, therapy => {
                vm.therapy = therapy;

                if (vm.therapy.singleRenewed) {
                    const singleVisit = vm.therapy.plan.visits[0];
                    vm.form.singleOverride = {
                        frequency: singleVisit.frequency,
                        reminderDay: singleVisit.reminderDay,
                        limitDays: singleVisit.limitDays,
                    };
                    if (vm.patientTherapy.singleOverride) {
                        if (vm.patientTherapy.singleOverride.frequency) {
                            vm.form.singleOverride.frequency = vm.patientTherapy.singleOverride.frequency;
                        }
                        if (vm.patientTherapy.singleOverride.reminderDay !== null) {
                            vm.form.singleOverride.reminderDay = vm.patientTherapy.singleOverride.reminderDay;
                        }
                        if (vm.patientTherapy.singleOverride.limitDays !== null) {
                            vm.form.singleOverride.limitDays = vm.patientTherapy.singleOverride.limitDays;
                        }
                    }
                    vm.minDay1DateIso = moment().format('YYYY-MM-DD');
                } else {
                    vm.form.singleOverride = null;
                    vm.minDay1DateIso = minPastDay1Date.format('YYYY-MM-DD');
                }

                preview();

            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function preview() {
            // ne pas demander la preview si aucun parcours n'est sélectionné
            if (!vm.form.therapyId) {
                return;
            }
            vm.hasVisitError = false;
            vm.isPreviewing = true;
            vm.previewError = null;
            let patientUserNeeded = false;
            PatientTherapy.startPreview({
                id: vm.patientTherapy.id,
            }, {
                day1Date: vm.form.day1Date,
                therapyId: vm.form.therapyId,
                singleOverride: vm.form.singleOverride,
            }).$promise.then(visits => {
                vm.visits = visits;
                for (const v of vm.visits) {
                    if (v.assigneeRequired && v.type !== 'PLUS_HOME') {
                        vm.visitTypes[v.type].assigneeRequired = true;
                    }
                    if (!vm.hasVisitError) {
                        if (v.assigneeRequired && !v.assigneeId) {
                            vm.hasVisitError = true;
                        }
                    }
                    if (v.type === 'PRO' && v.patientUserStatus === 'NO_USER') {
                        patientUserNeeded = true;
                    }
                }
                vm.patientUserNeeded = patientUserNeeded;
                vm.previewError = false;
            }).catch(() => {
                vm.patientUserNeeded = null;
                vm.previewError = true;
            }).finally(() => {
                vm.isPreviewing = false;

                $timeout(() => {
                    if (!deregisterSingleOverrideWatch) {
                        deregisterSingleOverrideWatch = watchSingleOverride();
                    }
                });
            });
        }

        function save() {
            vm.isSaving = true;
            PatientTherapy.start({
                id: vm.patientTherapy.id
            }, {
                day1Date: vm.form.day1Date,
                therapyId: vm.form.therapyId,
                patientEmail: vm.form.patientEmail,
                assigneeId: vm.form.assigneeId,
                singleOverride: vm.form.singleOverride
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $scope.$emit('continuumplatformApp:visitUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function resetPlan() {
            vm.visits = [];
            vm.visitTypes = {};
            vm.patientUserNeeded = null;
            vm.hasVisitError = false;
            vm.form.singleOverride = null;
        }

    }
})();
