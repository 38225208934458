(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitDetailController', VisitDetailController);

    VisitDetailController.$inject = ['$scope', '$rootScope', '$sce', '$state', '$stateParams', '$interval', '$filter', '$translate', '$uibModal', '$window', 'entity', 'previousState', 'Lightbox', 'Patient', 'PatientTherapy', 'Principal', 'Visit', 'MessageTo', 'Inbox', 'Practitioner'];

    function VisitDetailController($scope, $rootScope, $sce, $state, $stateParams, $interval, $filter, $translate, $uibModal, $window, entity, previousState, Lightbox, Patient, PatientTherapy, Principal, Visit, MessageTo, Inbox, Practitioner) {
        const vm = this;

        vm.plan = plan;
        vm.doConfirm = doConfirm;
        vm.undoConfirm = undoConfirm;
        vm.ack = ack;
        vm.reply = reply;
        vm.cancel = cancel;
        vm.reopen = reopen;
        vm.shiftOrPlan = shiftOrPlan;
        vm.openPractitioner = openPractitioner;
        vm.openFacility = openFacility;
        vm.openPatient = openPatient;
        vm.getNumber = (num) => new Array(num);

        vm.isExportingPdf = false;

        vm.interval = null;

        vm.$onInit = () => {
            vm.visit = entity;
            vm.savedName = vm.visit.name;

            vm.authorOrAssignee = vm.visit.author || vm.visit.assignee;

            // load basic patient data - will be overridden with full data
            vm.patient = vm.visit.patient;

            vm.previousState = previousState;

            if(entity.name) {
                $window.document.title = entity.name + ' #' + entity.id;
            } else {
                $translate('continuumplatformApp.VisitType.' + entity.type).then(title => {
                    $window.document.title = title + ' #' + entity.id;
                });
            }

            loadAccount();

            // si non traité, on recharge toutes les 10 secondes
            if (!vm.visit.ackDate) {
                vm.interval = $interval(() => {
                    Visit.get({id: vm.visit.id}, v => {
                        if (v.status !== vm.visit.status || v.ackDate !== vm.visit.ackDate) {
                            vm.visit = v;
                        }
                        if (vm.visit.ackDate) {
                            $interval.cancel(vm.interval);
                        }
                    });

                }, 10000);
            }

            const unsubscribe = $rootScope.$on('continuumplatformApp:visitUpdate', loadVisitAndNext);
            $scope.$on('$destroy', () => {

                unsubscribe();

                if (vm.interval) {
                    $interval.cancel(vm.interval);
                }

            });

            if (vm.visit.status === 'DONE') {

                Visit.getQuestions({id: vm.visit.id}, result => vm.questions = result.questions);

                Inbox.readVisitReport(vm.visit.id).then(response => {
                    // TODO improve with event listener
                    $rootScope.unreadMessages -= response.data;
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);

                    MessageTo.query({
                        reportOfVisitId: vm.visit.id,
                        size: 1000
                    }, recipients => vm.recipients = recipients.map(recipient => {
                        recipient.patientFacility = recipient.practitioner && recipient.practitioner.healthFacilityId === vm.patient.healthFacilityId;
                        return recipient;
                    }), () => {
                        // error is ignored
                    });
                });
            } else {
                loadReminderPractitioners();
            }
        };

        function loadAccount() {
            Principal.identity().then(account => {
                vm.account = account;
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.isPractitionerAllowedToReadPatientHealthData = account.practitioner && account.authorities.includes('PATIENT_HEALTH_READ');

                if (account.practitioner) {
                    vm.isPractitioner = true;
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isPharmacist = account.practitioner.job === 'J21';

                    Patient.get({id: vm.visit.patientId}, patient => vm.patient = patient);

                    if (vm.visit.patientTherapyId) {
                        PatientTherapy.get({id: vm.visit.patientTherapyId}, patientTherapy => vm.patientTherapy = patientTherapy);
                        loadNext();
                    }

                }
            });
        }

        function loadVisitAndNext() {
            Visit.get({id: vm.visit.id})
                .$promise
                .then((v) => {
                    vm.visit = v;
                    loadNext();
                });
        }

        function loadNext() {
            if(!vm.isPractitioner) {
                return;
            }
            vm.nextVisits = null;
            vm.nextVisitsCount = null;
            Visit.query({
                'patientTherapyId.equals': vm.visit.patientTherapyId,
                'orderingDate.greaterThanOrEqual': vm.visit.orderingDate,
                'id.notEquals': vm.visit.id,
                'sort': ['orderingDate'],
                size: 3
            }, (nextVisits, headers) => {
                vm.nextVisits = nextVisits;
                vm.nextVisitsCount = parseInt(headers('X-Total-Count'));
            });
        }

        function plan() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => angular.copy(vm.visit)
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function doConfirm() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-confirm-dialog.html',
                controller: 'VisitConfirmDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => angular.copy(vm.visit)
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function undoConfirm() {
            if(confirm('Le RDV va repasser au statut "à confirmer". Êtes-vous sûr ?')) {
                Visit.undoConfirm({id: vm.visit.id}, {}, loadVisitAndNext);
            }
        }

        function ack() {
            Visit.acknowledge({id: vm.visit.id}, {}, () => loadVisitAndNext());
        }

        function reply() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-reply-dialog.html',
                controller: 'VisitReplyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visitId: vm.visit.id
                }
            });
        }

        function cancel() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-cancel-dialog.html',
                controller: 'VisitCancelDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: vm.visit
                }
            }).result.then(result => {
                if (result === 'stopTherapy') {
                    $state.go('patient-therapy-detail', {id: vm.visit.patientTherapyId});
                }
            }, () => {
                // nothing
            });
        }

        function reopen() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-reopen-dialog.html',
                controller: 'VisitReopenDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: vm.visit,
                },
            }).result.then(result => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function shiftOrPlan() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-shift-or-plan-dialog.html',
                controller: 'VisitShiftOrPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: vm.visit
                }
            }).result.then(result => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: function () {
                                return practitioner;
                            }
                        }
                    });
                }
            });
        }

        function openFacility(facility) {
            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('health-facility-detail', {id: facility.id});
                    $window.open(url, 'health-facility-detail-' + facility.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                        controller: 'HealthFacilityModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            healthFacility: () => facility
                        }
                    });
                }
            });
        }

        function openPatient(patient) {
            $state.go('patient-detail.quality-of-life', { id: patient.id });
        }

        function loadReminderPractitioners() {
            vm.reminderPractitioners = {};
            if (vm.visit.reminders !== null && vm.visit.reminders.length) {
                const practitionerIds = vm.visit.reminders
                    .filter(reminder => reminder.practitionerId !== null)
                    .map(reminder => reminder.practitionerId);
                Practitioner.query({'id.in': practitionerIds})
                    .$promise
                    .then(practitioners =>
                        practitioners.forEach(practitioner =>
                            vm.reminderPractitioners[practitioner.id] = practitioner));
            }
        }

    }
})();
