(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientDraftController', PatientDraftController);

    PatientDraftController.$inject = ['$state', 'HealthFacility', 'Patient', 'Principal'];

    function PatientDraftController($state, HealthFacility, Patient, Principal) {
        const vm = this;

        vm.patient = {
            firstName: null,
            lastName: null,
            birthName: null,
            country: 'FR',
            healthFacilityId: null
        };

        vm.savePatient = savePatient;

        Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
            if (hasAuthority) {
                vm.isAdmin = true;
                vm.healthfacilities = HealthFacility.query({
                    view: 'summary',
                    size: 1000, // TODO search
                    sort: 'name',
                    "type.in": ['HOSPITAL']
                });
            }
        });

        function savePatient() {
            vm.isSaving = true;
            Patient.save(
                vm.patient,
                result => {
                    $state.go('patient-detail.request-therapy', {id: result.id});
                },
                () => {
                    vm.isSaving = false;
                });
        }

    }
})();
