(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerDialogController', PractitionerDialogController);

    PractitionerDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', '$q', '$window', '_', 'entity', 'Practitioner', 'User', 'HealthFacility', '$http', 'Directory', '$log', 'Principal', 'Address', 'options'];

    function PractitionerDialogController($timeout, $scope, $state, $stateParams, $uibModalInstance, $q, $window, _, entity, Practitioner, User, HealthFacility, $http, Directory, $log, Principal, Address, options) {
        const vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.confirmSave = confirmSave;
        vm.search = search;
        vm.selectExisting = selectExisting;
        vm.invitePractitioner = invitePractitioner;
        vm.skipSearch = skipSearch;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;
        vm.openPractitioner = openPractitioner;
        vm.toEditStep = toEditStep;

        vm.practitioner = entity;
        vm.isNew = !vm.practitioner.id;
        vm.searchStep = vm.isNew;
        vm.editStep = !vm.isNew;
        vm.addressInvalid = false;
        vm.readonlyIdentity = !!vm.practitioner.rpps;

        if (options) {
            vm.options = options;
            vm.searchMode = options.mode;
            vm.searchCriteria = options.searchCriteria || {
                address: null,
                postalCode: null,
                city: null,
                latitude: null,
                longitude: null
            };
            vm.criteria = {
                page: 1,
                size: 20,
                job: vm.practitioner.job,
                firstName: null,
                lastName: null,
                idpp: null,
                address: !vm.searchCriteria.latitude && !vm.searchCriteria.longitude ? vm.searchCriteria.postalCode : null,
                latitude: vm.searchCriteria.latitude,
                longitude: vm.searchCriteria.longitude,
                legalStatus: vm.searchCriteria.legalStatus
            };
        }

        vm.jobs = Practitioner.getJobs();

        vm.categories = Practitioner.getCategories();

        Practitioner.getSpecialities().then(function (response) {
            vm.specialities = response;
        }).catch(function (response) {
            vm.specialities = [];
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.$onInit = function () {
            Principal.hasAuthority('ROLE_SECURITY').then(hasSecurityRole => {
                vm.hasSecurityRole = hasSecurityRole;
                if (vm.hasSecurityRole && vm.practitioner.userId) {
                    User.get({id: vm.practitioner.userId}, function (result) {
                        vm.practitioner.userAuthType = result.authType;
                    });
                }
            });

            if(vm.practitioner.healthFacilityId) {
                HealthFacility.get({id: vm.practitioner.healthFacilityId, view: 'summary'}, facility => {
                    vm.healthFacility = facility;
                    init();
                });
            } else {
                init();
            }
        };

        function init() {
            switch (vm.searchMode) {

            case 'addHealthFacility':
                if (!vm.practitioner.healthFacilityId) {
                    $log.error("Missing Practitioner ID");
                    return;
                }
                if (vm.healthFacility.finessEt) {
                    // recherche exacte par FINESS établissement
                    vm.criteria.finessEt = vm.healthFacility.finessEt;
                } else if (vm.healthFacility.latitude && vm.healthFacility.longitude) {
                    // recherche à proximité de la localisation de l'établissement
                    vm.address = `${vm.healthFacility.address || ''} ${vm.healthFacility.postalCode || ''} ${vm.healthFacility.city || ''}`.trim();
                    vm.criteria.address = null;
                    vm.criteria.latitude = vm.healthFacility.latitude;
                    vm.criteria.longitude = vm.healthFacility.longitude;
                } else {
                    // recherche par nom et code postal
                    vm.address = vm.healthFacility.postalCode;
                    vm.criteria.address = vm.healthFacility.postalCode;
                    vm.criteria.healthFacilityName = vm.healthFacility.legalName;
                }
                vm.search();
                break;

            case 'creation':
            case 'addTeam':

            case 'edition':
                vm.address = {
                    properties: {
                        label: `${vm.practitioner.address || ''} ${vm.practitioner.postalCode || ''} ${vm.practitioner.city || ''}`
                    }
                };
                break;

            default:
                $log.error("Unsupported mode: " + vm.searchMode);
                break;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

            vm.isSaving = true;

            if (!vm.practitioner.userAuthType
                || !vm.practitioner.email
                || vm.practitioner.userStatus === 'DISABLED'
                || vm.practitioner.userAuthType === 'BLOCKED') {
                vm.practitioner.notify = false;
            }

            vm.existingPractitionersWithSameName = false;
            vm.existingPractitionersWithSameRpps = false;

            if (vm.practitioner.id) {

                Practitioner.update(vm.practitioner, onSaveSuccess, onSaveError);

            } else if(vm.hasSecurityRole) {

                // On vérifie dans la base de données si un PS ne correspondrait pas à celui qu'on veut créer
                checkExistingPractitioners(vm.practitioner).then((existingPractitionersFromDataBase) => {

                    if (vm.practitioner.rpps && existingPractitionersFromDataBase && existingPractitionersFromDataBase.length) {
                        vm.existingPractitionersWithSameRpps = true;

                    } else if (existingPractitionersFromDataBase && existingPractitionersFromDataBase.length) {
                        vm.existingPractitionersWithSameName = true;
                    }

                    // on a trouvé des PS pouvant correspondre on demande confirmation pour la création
                    if (existingPractitionersFromDataBase.length > 0) {
                        vm.confirmStep = true;
                        vm.searchStep = false;
                        vm.editStep = false;
                        vm.isSaving = false;
                    } else {
                        Practitioner.save(vm.practitioner, onSaveSuccess, onSaveError);
                    }

                });
            } else {
                Practitioner.save(vm.practitioner, onSaveSuccess, onSaveError);
            }
        }

        function checkExistingPractitioners(practitioner) {
            return Practitioner.query(
                {
                    'nationalIdentifier.equals': practitioner.rpps ? practitioner.rpps : undefined,
                    'firstName.equalsNormalized': !practitioner.rpps ? vm.practitioner.firstName: undefined,
                    'lastName.equalsNormalized': !practitioner.rpps ? vm.practitioner.lastName: undefined,
                    'job.equals': !practitioner.rpps ? vm.practitioner.job: undefined,
                })
                .$promise
                .then((existingPractitioners) => vm.existingPractitionersFromDataBase = existingPractitioners);
        }

        function confirmSave() {
            Practitioner.save(vm.practitioner, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:practitionerUpdate', result.id);
            $uibModalInstance.close(result.id);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function selectExisting(id) {
            onSaveSuccess({id});
        }

        function invitePractitioner(result) {
            vm.practitioner = Directory.invite(result, vm.practitioner);

            vm.address = {
                properties: {
                    label: `${vm.practitioner.address || ''} ${vm.practitioner.postalCode || ''} ${vm.practitioner.city || ''}`
                }
            };

            if (vm.healthFacility) {
                vm.practitioner.healthFacilityName = vm.healthFacility.name;
                vm.practitioner.email = vm.healthFacility.email;

                if (!vm.practitioner.cityCode) {
                    vm.practitioner.cityCode = vm.healthFacility.cityCode;
                }

                if (!vm.practitioner.longitude && !vm.practitioner.latitude) {
                    vm.practitioner.longitude = vm.healthFacility.longitude;
                    vm.practitioner.latitude = vm.healthFacility.latitude;
                }

            }
            vm.addressInvalid = false;
            vm.searchStep = false;
            vm.editStep = true;
            vm.readonlyIdentity = true;
            $timeout(function () {
                angular.element('.form-group:eq(0)>input').focus();
            });
        }

        function skipSearch() {
            vm.practitioner.job = vm.criteria.job;
            vm.practitioner.firstName = vm.criteria.firstName;
            vm.practitioner.lastName = vm.criteria.lastName;
            vm.practitioner.rpps = vm.criteria.idpp && vm.criteria.idpp.length === 11 ? vm.criteria.idpp : null;
            vm.practitioner.adeli = vm.criteria.idpp && vm.criteria.idpp.length === 9 ? vm.criteria.idpp : null;

            if (vm.healthFacility) {
                if (!vm.practitioner.cityCode) {
                    vm.practitioner.cityCode = vm.healthFacility.cityCode;
                }

                if (!vm.practitioner.longitude && !vm.practitioner.latitude) {
                    vm.practitioner.longitude = vm.healthFacility.longitude;
                    vm.practitioner.latitude = vm.healthFacility.latitude;
                }
            }

            vm.addressInvalid = false;
            vm.searchStep = false;
            vm.editStep = true;
            vm.readonlyIdentity = false;
        }

        function search() {
            vm.criteria.address = !vm.criteria.latitude && !vm.criteria.longitude ? vm.address : undefined;

            const req = Directory.search(vm.criteria);

            $http(req).then(function (response) {
                vm.practitionersFromDirectory = response.data.content;
                vm.totalPractitionersFromDirectory = response.data.totalElements;
            }, function (error){
                vm.practitionersFromDirectory = null;
                $log.error("Error from api", error);
            });
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            vm.criteria.longitude = null;
            vm.criteria.latitude = null;
            vm.criteria.address = null;

            vm.practitioner.address = null;
            vm.practitioner.postalCode = null;
            vm.practitioner.city = null;
            vm.practitioner.latitude = null;
            vm.practitioner.longitude = null;
            vm.practitioner.cityCode = null;

            if (!search || search.length < 3) {
                return;
            }

            return Address.search(search);
        }

        function onSelectAddress($item, $model, $label, $event)  {
            vm.addressInvalid = false;
            if (vm.practitioner) {
                vm.practitioner.address = ['housenumber', 'street', 'locality'].includes($item.properties.type) ? $item.properties.name : null;
                vm.practitioner.postalCode = $item.properties.postcode;
                vm.practitioner.city = $item.properties.city;

                vm.practitioner.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : null;
                vm.practitioner.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : null;
                vm.practitioner.cityCode = $item.properties.citycode;
            }

            if (vm.criteria.latitude !== $item.geometry.coordinates[1] && vm.criteria.longitude !== $item.geometry.coordinates[0]) {
                vm.criteria.address = null;
                vm.criteria.latitude = $item.geometry.coordinates[1];
                vm.criteria.longitude = $item.geometry.coordinates[0];
            }
        }

        function openPractitioner(practitionerId) {
            const url = $state.href('practitioner-detail', {id: practitionerId});
            $window.open(url, 'practitioner-detail-' + practitionerId);
        }

        function toEditStep() {
            vm.confirmStep = false;
            vm.searchStep = false;
            vm.editStep = true;
        }
    }
})();
