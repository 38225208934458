(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerDetailController', PractitionerDetailController);

    PractitionerDetailController.$inject = ['$scope', '$rootScope', '$state', '$http', '$log', '$uibModal', '$filter', '$window', 'entity', 'Practitioner', 'Visit', 'Patient', 'Principal', 'Directory'];

    function PractitionerDetailController($scope, $rootScope, $state, $http, $log, $uibModal, $filter, $window, entity, Practitioner, Visit, Patient, Principal, Directory) {
        const vm = this;

        vm.nationalIdentifierCheck = null;
        vm.practitioner = entity;

        vm.$onInit = function () {
            $window.document.title = $filter('translatePractitionerName')(entity, 'TITLE_FULLNAME_JOB_SPECIALITY');

            Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
                if (hasAuthority) {
                    loadVisits();
                    loadPatients();
                }
            });

            checkNationalIdentifier();

            vm.unsubscribePractitionerUpdate = $rootScope.$on('continuumplatformApp:practitionerUpdate', () => {
                vm.nationalIdentifierCheck = null;
                loadPractitioner().then(checkNationalIdentifier);
            });
        };

        $scope.$on('$destroy', function () {
            vm.unsubscribePractitionerUpdate();
        });

        function loadVisits() {
            return Visit.query({
                "authorId.equals": vm.practitioner.id,
                "sort": "date,asc",
                "size": 1000 // TODO pagination?
            }).$promise.then(visits =>
                vm.visits = visits);
        }

        function loadPatients() {
            return Patient.query({
                "hasCareTeamMemberId": vm.practitioner.id,
                "sort": "lastName,asc",
                "size": 1000 // TODO pagination?
            }).$promise.then(patients =>
                vm.patients = patients);
        }

        function loadPractitioner() {
            return Practitioner.get({
                id: vm.practitioner.id
            }).$promise.then(practitioner =>
                vm.practitioner = practitioner);
        }

        function checkNationalIdentifier() {
            doCheckRppsOrAdeli(vm.practitioner.rpps ? vm.practitioner.rpps : vm.practitioner.adeli)
                .then((check) => vm.nationalIdentifierCheck = check)
                .catch(() => {
                });
        }

        function doCheckRppsOrAdeli(rppsOrAdeli) {

            if (!rppsOrAdeli) {
                return Promise.reject('No identifier to check');
            }

            const criteria = {
                page: 1,
                size: 1,
                idpp: rppsOrAdeli
            };

            const req = Directory.search(criteria);

            return $http(req).then(
                response => response.data.totalElements > 0,
                error => $log.error("Error from api", error));
        }
    }
})();
