(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MessagingController', MessagingController);

    MessagingController.$inject = ['$rootScope', '$scope', '$state', '$location', '$anchorScroll', '$timeout', '$filter', 'Inbox', 'Message', 'ParseLinks', 'AlertService', 'Principal', 'CareTeam', 'currentMessage'];

    function MessagingController($rootScope, $scope, $state, $location, $anchorScroll, $timeout, $filter, Inbox, Message, ParseLinks, AlertService, Principal, CareTeam, currentMessage) {

        const vm = this;

        vm.folder = null;
        vm.patientId = null;
        vm.patientGroups = null;
        vm.patientGroupsGroupArray = [];
        vm.filteredpatientGroupsGroupArray = [];
        vm.messages = null;
        vm.currentMessage = currentMessage;
        vm.messageImportant = null;
        vm.filter = '';
        vm.userInPatientHospitalTeam = null;

        vm.selectGroup = selectGroup;
        vm.clearFilter = clearFilter;
        vm.openPatient = openPatient;
        vm.closeAwayMessage = closeAwayMessage;

        $scope.$watch("vm.filter", function (newVal, oldVal) {
            vm.filteredPatientGroupsGroupArray = filterPatientGroups(newVal);
        });

        Principal.identity().then(function (account) {
            vm.currentUser = account;
            vm.isPractitioner = account.authorities.includes('ROLE_PRACTITIONER');
            vm.isAllowedToReadPatientHealthData = account.authorities.includes('PATIENT_HEALTH_READ');

            if (vm.isPractitioner) {

                Inbox.getSummary().then(function (response) {

                    vm.patientGroups = new Map();
                    response.data.forEach( (value) => {
                        vm.patientGroups.set('P' + value.patientId, value);
                    });

                    vm.patientGroupsGroupArray = [...vm.patientGroups.values()];
                    vm.filteredPatientGroupsGroupArray = filterPatientGroups(vm.filter);

                    // if a message is specified
                    if (vm.currentMessage) {

                        selectGroup(vm.patientGroups.get('P' + currentMessage.message.patientId))
                            .then(function () {

                                // scroll to message
                                $location.hash("msg-" + vm.currentMessage.id);
                                $anchorScroll();
                            });

                    }

                });
            }
        });

        function loadMessages() {
            if (vm.isPractitioner) {
                if (!vm.patientId) {
                    return Promise.reject('No patient selected');
                }
                return Message.query({
                    'patientId.equals': vm.patientId,
                    sort: 'date',
                }).$promise.then(messages =>
                    vm.messages = messages
                );
            }
            return Promise.reject('No messaging for this user');
        }

        function markAllRead(patientId) {
            Inbox.readAll(patientId).then(function (response) {
                if (response.data) {
                    // TODO improve with event listener
                    if (patientId) {
                        vm.patientGroups.get('P' + patientId).countUnread = 0;
                    }
                    $rootScope.unreadMessages -= response.data;
                    var now = new Date();
                    angular.forEach(vm.messages, function (v, i) {
                        vm.messages[i].readDate = now;
                    });
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);
                }
            });
        }

        function selectGroup(group) {

            vm.folder = group;

            vm.patientId = group.patientId;

            vm.messages = null;

            loadCareTeam().then(() => {
                vm.userInPatientHospitalTeam = isUserInPatientHospitalTeam(vm.currentUser, vm.careTeam);
            });

            loadMessages().then(() => {
                if (vm.patientGroups.get('P' + vm.patientId).countUnread) {
                    $timeout(() => markAllRead(vm.patientId), 1000);
                }
            });

        }

        function filterPatientGroups(filterValue) {
            if (!filterValue || filterValue.trim() === '') {
                return vm.patientGroupsGroupArray;
            }

            const cleanFilterValue = filterValue
                .normalize('NFKD')
                .replace(/\p{Diacritic}/gu, "")
                .replace(/[^\p{Alpha}]/gu, "")
                .toUpperCase();

            return vm.patientGroupsGroupArray.filter((item) => item.search && item.search.includes(cleanFilterValue));
        }

        function clearFilter() {
            vm.filter = null;
        }

        function openPatient(patientId) {
            if(vm.isAllowedToReadPatientHealthData) {
                $state.go('patient-detail.quality-of-life', { id: patientId });
            } else {
                $state.go('patient-detail.team', {id: patientId});
            }
        }

        function loadCareTeam() {
            vm.careTeam = [];
            return CareTeam.getMembers(vm.patientId).then(response => {
                vm.careTeam = response.data;
                return vm.careTeamMemberAway = CareTeam.getAwayMembers(vm.careTeam);
            });
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

        function isUserInPatientHospitalTeam(account, careTeam) {
            const hospitalIds = careTeam.filter((m) => m.role === 'HOSPITAL').map(m => m.entity.id);
            return account.practitioner && hospitalIds.includes(account.practitioner.healthFacilityId);
        }
    }
})();
