(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyRequestController', PatientTherapyRequestController);

    PatientTherapyRequestController.$inject = ['$rootScope', '$state', '$timeout', '$scope', '$filter', '$uibModal', '$log', '$window', 'moment', 'entity', 'Patient', 'PatientTherapy', 'Practitioner', 'Principal', 'CareTeam'];

    function PatientTherapyRequestController($rootScope, $state, $timeout, $scope, $filter, $uibModal, $log, $window, moment, entity, Patient, PatientTherapy, Practitioner, Principal, CareTeam) {
        const vm = this;

        vm.cancel = cancel;
        vm.save = save;

        vm.patient = entity;

        vm.requesterId = null;
        vm.createPatientUser = !vm.patient.userId;

        vm.team = {};
        CareTeam.getMembers(vm.patient.id).then(response => {
            response.data.forEach(member => {
                if (member.role === 'FACILITY_DOCTOR') {
                    vm.team.facilityDoctorId = member.entity.id;
                } else if (member.role === 'FACILITY_PHARMACIST') {
                    vm.team.facilityPharmacistId = member.entity.id;
                } else if (member.role === 'NURSE_COORD') {
                    vm.team.nurseCoordId = member.entity.id;
                }
            });
        });

        vm.practitioners = [];

        vm.day1Date = null;

        // prevent user from exiting
        $rootScope.warnBeforeUnload = true;
        vm.isCancelDialogOpened = false;
        vm.uiCanExit = transition => {
            if (vm.isCancelDialogOpened) {
                history.pushState(null, $window.document.title, location.href);
                return false;
            }

            if (['patient-detail.edit-identity', 'patient-detail.edit-address', 'patient-detail.edit-user'].includes(transition.to().name)) {
                return true;
            }

            if ($rootScope.warnBeforeUnload) {
                vm.cancel(transition);
                history.pushState(null, $window.document.title, location.href);
                return false;
            }
            vm.isCancelDialogOpened = false;
        };

        // load health pro
        Principal.identity().then(account => {

            if (account.authorities.includes('ROLE_ADMIN')) {

                vm.isAdmin = true;

            } else {

                const practitioner = account.practitioner;

                if (practitioner.job === 'J10') {
                    vm.isDoctor = true;
                } else if (practitioner.job === 'J60') {
                    vm.isNurse = true;
                } else if (practitioner.job === 'J21') {
                    vm.isPharmacist = true;
                }

                if (vm.isNurse && practitioner.healthFacilityType === 'HOSPITAL') {
                    vm.team.nurseCoordId = practitioner.id;
                } else if (vm.isPharmacist && practitioner.healthFacilityType === 'HOSPITAL') {
                    vm.team.facilityPharmacistId = practitioner.id;
                } else if (vm.isDoctor) {
                    vm.team.facilityDoctorId = practitioner.id;
                }
            }

            loadPractitioners(vm.patient.healthFacilityId);
        });

        function cancel(transition) {
            $rootScope.warnBeforeUnload = false;

            if (vm.patient.deletable) {
                vm.isCancelDialogOpened = true;
                $uibModal.open({
                    templateUrl: 'app/activity/patient/patient-therapy-cancel-request.modal.html',
                    controller: 'PatientTherapyCancelRequestModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        currentPatient: () => vm.patient,
                    }

                }).result.then(result => {
                    vm.isCancelDialogOpened = false;
                    if (result === 'saveDraft') {
                        if (transition) {
                            $state.go(transition.to().name);
                        } else {
                            $state.go('patient-detail.therapies', {id: vm.patient.id});
                        }
                    } else if (result === 'deleteDraft') {
                        vm.isDeleting = true;
                        Patient.delete({id: vm.patient.id}, () => {
                            // do nothing
                        }, () => vm.isDeleting = false);
                        if (transition) {
                            $state.go(transition.to().name);
                        } else {
                            $state.go('home');
                        }
                    }

                }, () => {
                    $rootScope.warnBeforeUnload = true;
                    vm.isCancelDialogOpened = false;
                });

            } else if (confirm("Êtes-vous sûr(e) ?")) {
                if (transition) {
                    if (['patient-detail.edit-identity', 'patient-detail.edit-address', 'patient-detail.edit-user'].includes(transition.to().name)) {
                        $state.go(transition.to().name, {id: vm.patient.id});
                    } else {
                        $state.go(transition.to().name);
                    }
                } else {
                    $state.go('patient-detail.therapies', {id: vm.patient.id});
                }
            }
        }

        function save() {
            vm.isSaving = true;
            $rootScope.warnBeforeUnload = false;

            PatientTherapy.save({
                patientId: vm.patient.id,
                patientEmail: vm.patient.email,
                patientMobilePhone: vm.patient.mobilePhone,
                requesterId: vm.requesterId,
                doctorId: vm.team.facilityDoctorId,
                nurseCoordId: vm.team.nurseCoordId,
                facilityPharmacistId: vm.team.facilityPharmacistId,
                trialId: vm.trialId,
                createPatientUser: vm.createPatientUser,
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go('patient-therapy-detail', {id: result.patientTherapyId});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadPractitioners(healthFacilityId) {
            Practitioner.query({
                "healthFacilityId.equals": healthFacilityId || undefined,
                "healthFacilityType.equals": healthFacilityId ? undefined : "HOSPITAL",
                sort: "lastName",
                size: 1000 // TODO search
            }, practitioners => {
                vm.practitioners = practitioners;
            });
        }

    }
})();
