(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientEditUserController', PatientEditUserController);

    PatientEditUserController.$inject = ['$filter', '$timeout', '$uibModalInstance', 'entity', 'Patient', 'Principal', 'User'];

    function PatientEditUserController($filter, $timeout, $uibModalInstance, entity, Patient, Principal, User) {
        var vm = this;

        vm.form = {
            email: entity.email,
            mobilePhone: entity.mobilePhone,
        };

        vm.fullName = $filter('patientName')(entity);
        vm.country = entity.country;
        vm.userId = entity.userId;
        vm.userStatus = entity.userStatus;

        vm.cancel = cancel;
        vm.save = save;

        vm.notify = !entity.userId;

        vm.authType = null;
        Principal.hasAuthority('ROLE_SECURITY').then(hasSecurityRole => {
            vm.hasSecurityRole = hasSecurityRole;
            if (vm.hasSecurityRole && vm.userId) {
                User.get({id: vm.userId}, function (result) {
                    vm.authType = result.authType;
                });
            }
        });

        $timeout(function () {
            angular.element('#field_email').focus();
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.form.authType = vm.authType || guessAuthType();
            vm.form.notify = vm.notify;
            Patient.updateUser({
                id: entity.id
            }, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function guessAuthType() {
            if (!vm.form.email)
                return null;

            if (vm.form.mobilePhone)
                return 'SMS';

            return 'EMAIL';
        }
    }
})();
