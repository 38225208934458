(function () {
    'use strict';

    const dashboard = {
        bindings: {
        },
        controller: DashboardController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/dashboard.html'
    };

    angular
        .module('continuumplatformApp')
        .component('dashboard', dashboard);

    DashboardController.$inject = ['$scope', 'Principal', 'Account', 'Patient', 'Practitioner', 'HealthFacility'];

    function DashboardController($scope, Principal, Account, Patient, Practitioner, HealthFacility) {

        const vm = this;

        // Initializes properties
        vm.initFinish = false;
        vm.today = new Date();

        /**
         * Contains reusable components for the virtual machine.
         *
         * @type {Array} An array containing objects representing the dashboard components.
         * Each object contains the following properties:
         * - component: The name of the dashboard component.
         * - width: The width of the component.
         * - mdWidth: The width of the component for medium-sized screens.
         * - lgWidth : The width of the component for large-sized screens.
         * - row: The row number of the component in the grid
         */
        vm.components = null;
        vm.filters = {
            facilityDoctorId: undefined,
            coordinationPractitionerId: undefined,
        };

        vm.onChangeCoordinationPractitioner = onChangeCoordinationPractitioner;

        /**
         * Initialize the component.
         */
        vm.$onInit = () => {
            getAccount()
                .then(account => {
                    if(account.practitioner) {
                        vm.practitioner = account.practitioner;
                        return account;
                    }
                    return Promise.reject('No practitioner found');
                })
                .then(() => loadFacility())
                .then(() => {
                    if (vm.isHospitalUser && vm.isDoctor) {
                        return loadFacilityDoctor();
                    } else if (vm.isCoordination) {
                        return loadCoordinationPractitioners(vm.practitioner.healthFacilityId);
                    }
                }).then(() => {
                    vm.components = getDefaultComponentSetting();
                });

        };

        vm.$onDestroy = () => {

        };

        /**
         * Retrieves the current user's account information.
         *
         * @returns {Promise} A Promise object that resolves to the account information.
         */
        function getAccount() {
            return Principal.identity().then(function (account) {

                if (account) {
                    vm.account = account;
                    vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

                    if (account.practitioner) {
                        vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                        vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                        vm.isDoctor = account.practitioner.job === 'J10';
                        vm.isPharmacist = account.practitioner.job === 'J21';
                        vm.isNurse = account.practitioner.job === 'J60';
                        vm.isOtherPractitioner = account.practitioner.job === 'J99';
                    }
                }

                return vm.account;
            });
        }

        function loadFacility() {
            if (!vm.practitioner.healthFacilityId) {
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: vm.practitioner.healthFacilityId
            }, facility => {
                vm.facility = facility;
            }).$promise;
        }

        /**
         * Retrieves the default component settings based on the user type.
         *
         * @return {{component: string, width: number, mdWidth: number, row?: number}[]}
         */
        function getDefaultComponentSetting() {
            let components = [];

            if (vm.isCoordination && vm.isAdmin) {
                components = defineDashboardComponentForContinuumCoordination();
            } else if (vm.isHospitalUser) {
                components = defineDashboardComponentForHospital();
            }

            return components;
        }

        function defineDashboardComponentForContinuumCoordination() {
            return [];
        }

        function defineDashboardComponentForHospital() {
            return [
                {component: 'new-message-block'},
                {component: 'hdj-block'},
                {component: 'follow-up-to-start-block'},
                {component: 'suspended-follow-up-block'},
            ];
        }

        function loadFacilityDoctor() {
            vm.filters.facilityDoctorId = vm.practitioner.id;
        }

        function loadCoordinationPractitioners(healthFacilityId) {
            const filter = 'coordinationPractitionerId';
            const stored = sessionStorage.getItem('dashboard.filters.' + filter);
            if(stored) {
                vm.filters[filter] = parseInt(stored);
            }
            return loadPractitioners(Practitioner.query,  filter, healthFacilityId);
        }

        function loadPractitioners(fn, filter, healthFacilityId) {

            if (angular.isUndefined(vm.filters[filter])) {
                vm.selectedPractitioner = vm.practitioner;
                vm.filters[filter] = vm.practitioner.id;
            }
            vm.forcePractitioner = vm.practitioner;

            return fn({
                "healthFacilityId.equals": healthFacilityId,
            }).$promise.then(practitioners => {
                vm.practitioners = practitioners;
                if (vm.forcePractitioner && (!practitioners.length || !practitioners.find(practitioner => practitioner.id === vm.forcePractitioner.id))) {
                    vm.practitioners.push(vm.forcePractitioner);
                }
                if (vm.filters[filter]) {
                    vm.selectedPractitioner = practitioners.find(practitioner => practitioner.id === vm.filters[filter]);
                }
                return vm.practitioners;
            });
        }

        function onChangeCoordinationPractitioner(practitioner) {
            onChangePractitioner('coordinationPractitionerId', practitioner);
        }

        function onChangePractitioner(filter, practitioner) {
            if (practitioner) {
                vm.filters[filter] = practitioner.id;
                vm.selectedPractitioner = practitioner;
            } else {
                vm.filters[filter] = null;
                vm.selectedPractitioner = null;
            }
            sessionStorage.setItem('dashboard.filters.' + filter, vm.filters[filter]);
        }

    }

})();
