(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Export', Export);

    Export.$inject = ['$rootScope', '$http'];

    function Export($rootScope, $http) {

        return {exportCSV};

        function exportCSV(file) {
            return $http.get('api/portability/' + file + '.csv');
        }

    }

})();
