(function () {
    'use strict';

    const messageAttachments = {
        bindings: {
            message: '='
        },
        controller: MessageAttachmentsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/message/message-attachments.html'
    };

    angular
        .module('continuumplatformApp')
        .component('messageAttachments', messageAttachments);

    MessageAttachmentsController.$inject = ['$rootScope', '$sce', '$scope', '$state', '$log', '$uibModal', '$window', 'Principal', 'Lightbox'];

    function MessageAttachmentsController($rootScope, $sce, $scope, $state, $log, $uibModal, $window, Principal, Lightbox) {

        const vm = this;

        vm.$onInit = function () {
            getAccount();
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

                if (account.practitioner) {
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                }
            });
        }

    }

})();
