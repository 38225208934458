(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-therapy', {
                parent: 'patients',
                url: '/patient-therapy?page&sort',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.patient-therapy-list'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-list.html',
                        controller: 'PatientTherapyListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'status,asc',
                        squash: true
                    },
                    filters: {
                        value: {
                            healthFacilityId: null,
                            prescriberId: null,
                            status: ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED"],
                        }
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('coordination');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacilityType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-therapy.request', {
                url: '/request',
                data: {
                    authorities: ['PATIENT_THERAPY_REQUEST']
                },
                redirectTo: (trans) => 'patient-draft'
            })
            .state('patient-therapy-detail', {
                parent: 'patient-therapy',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-detail.html',
                        controller: 'PatientTherapyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('practitioner');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PatientTherapy', function ($stateParams, PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
