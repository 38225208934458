(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ExportController', ExportController);

    ExportController.$inject = ['$log', '$window', 'Export', 'DownloadFile'];

    function ExportController($log, $window, Export, DownloadFile) {

        $window.document.title = "Export";

        const vm = this;

        vm.account = null;

        vm.files = [
            'PROFESSIONNELS',
            'SUIVIS',
            'QUESTIONNAIRES',
            'QDV',
            'TEMPS'];

        vm.exporting = {};
        for (let file of vm.files) {
            vm.exporting[file] = false;
        }
        vm.exportError = null;

        vm.$onInit = () => {
        };

        vm.$onDestroy = () => {

        };

        vm.export = (file) => {
            vm.exporting[file] = true;
            vm.exportError = null;
            Export.exportCSV(file)
                .then(response => DownloadFile.downloadFile(transformExport(response), true))
                .catch(response => {
                    vm.exportError = response.data ? response.data.detail : 'Erreur';
                })
                .finally(() => {
                    vm.exporting[file] = false;
                });
        };

        function transformExport(response) {
            return {
                data: new Blob([response.data], {type: response.headers('Content-Type')}),
                headers: response.headers
            };
        }

    }
})();
