(function () {
    'use strict';

    const versionPractitioner = 12;

    angular
        .module('continuumplatformApp')
        .constant('termsConstants', {
            'versionPractitioner': versionPractitioner,
            'inlineUrlPractitioner': 'https://files.continuumplus.net/public/cgu-practitioner.pdf?disposition=inline&v=' + versionPractitioner,
            'attachmentUrlPractitioner': 'https://files.continuumplus.net/public/cgu-practitioner.pdf?disposition=attachment&v=' + versionPractitioner,
        });
})();
