(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('DirectController', DirectController);

    DirectController.$inject = ['$state', 'facilityPatientId', 'Patient', 'Principal'];

    function DirectController($state, facilityPatientId, Patient, Principal) {

        const vm = this;

        vm.$onInit = () => {
            if (facilityPatientId === null || (facilityPatientId && facilityPatientId.trim() === '')) {
                vm.error = "IPP manquant.";
                return;
            }
            Principal.identity().then(account => {
                if (!account.practitioner.healthFacilityId || account.practitioner.healthFacilityType !== 'HOSPITAL') {
                    vm.error = "L'accès direct par IPP est réservé au personnel hospitalier ou assimilé.";
                    return;
                }
                Patient.query({
                    internalId: facilityPatientId,
                    'healthFacilityId.equals': account.practitioner.healthFacilityId
                }, patients => {
                    if (patients.length === 0) {
                        redirectNotFound();
                    } else if (patients.length === 1) {
                        $state.go('patient-detail', {id: patients[0].id});
                    } else {
                        vm.error = 'Plusieurs correspondances trouvées !';
                    }
                }, () => {
                    vm.error = 'Erreur lors de la recherche.';
                });
            });
        };

        function redirectNotFound() {
            $state.go('patient-draft');
        }

    }
})();
