(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityDetailController', HealthFacilityDetailController);

    HealthFacilityDetailController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', '$filter', '$window', 'entity', 'HealthFacility', 'Practitioner', 'Patient', 'Principal'];

    function HealthFacilityDetailController($scope, $rootScope, $state, $uibModal, $filter, $window, entity, HealthFacility, Practitioner, Patient, Principal) {
        var vm = this;

        vm.healthFacility = entity;
        vm.patients = null;

        vm.edit = edit;
        vm.remove = remove;
        vm.addPractitioner = addPractitioner;
        vm.addFacility = addFacility;

        $window.document.title = entity.name;

        loadPractitioners();
        loadFacilities();

        Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
            if (hasAuthority) {
                loadPatients();
            }
        });

        var unsubscribePractitionerUpdate = $rootScope.$on('continuumplatformApp:practitionerUpdate', loadPractitioners);
        $scope.$on('$destroy', () => {
            unsubscribePractitionerUpdate();
        });

        function loadFacility() {
            HealthFacility.get({
                id: vm.healthFacility.id
            }, function (result) {
                vm.healthFacility = result;
            });
        }

        function loadFacilities() {
            if (vm.healthFacility.type === 'HOSPITAL' && vm.healthFacility.healthFacilityId === null) {
                HealthFacility.query({
                    "healthFacilityId.equals": vm.healthFacility.id,
                    sort: "name,asc",
                    size: 1000 // TODO pagination?
                }, function (facilities) {
                    vm.facilities = facilities;
                });
            }
        }

        function loadPractitioners() {
            Practitioner.query({
                "healthFacilityId.equals": vm.healthFacility.id,
                sort: "lastName,asc",
                size: 1000 // TODO pagination?
            }, function (practitioners) {
                vm.practitioners = practitioners;
            });
        }

        function loadPatients() {
            if (!['NURSING', 'PHARMACY'].includes(vm.healthFacility.type)) {
                return;
            }

            Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
                if (hasAuthority) {
                    Patient.query({
                        hasCareTeamMemberId: vm.healthFacility.id,
                        "sort": "lastName,asc",
                        "size": 1000
                    }, patients => vm.patients = patients);
                }
            });
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-dialog.html',
                controller: 'HealthFacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['HealthFacility', (HealthFacility) =>
                        HealthFacility.get({id: vm.healthFacility.id}).$promise]
                }
            }).result.then(result => {
                vm.healthFacility = result;
            }, () => {
                // do nothing
            });
        }

        function remove() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-delete-dialog.html',
                controller: 'HealthFacilityDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['HealthFacility', (HealthFacility) =>
                        HealthFacility.get({id: vm.healthFacility.id}).$promise]
                }
            }).result.then(() => {
                $state.go('health-facility', null, {reload: 'health-facility'});
            }, () => {
                // do nothing
            });
        }

        function addPractitioner() {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        healthFacilityId: vm.healthFacility.id,
                        job: null,
                        firstName: null,
                        lastName: null,
                        speciality: null,
                        category: null,
                        rpps: null,
                        adeli: null,
                        legalStatus: null,
                        legalRegistrationNumber: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        notes: null,
                        userAuthType: null,
                        emailShown: false,
                        mobilePhoneShown: false,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addHealthFacility'
                    }
                }
            }).result.then(function () {
            }, function () {
            });
        }

        function addFacility() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-dialog.html',
                controller: 'HealthFacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => ({
                        healthFacilityId: vm.healthFacility.id,
                        name: null,
                        legalName: null,
                        type: null,
                        siret: null,
                        finessEt: null,
                        finessEj: null,
                        phone: null,
                        fax: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        cityCode: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        validated: false,
                        status: null,
                        notes: null,
                        id: null
                    })
                }
            }).result.then(() => {
                loadFacilities();
            }, () => {
                // do nothing
            });
        }

    }
})();
