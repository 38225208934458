(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientTherapy', PatientTherapy);

    PatientTherapy.$inject = ['$http', '$resource', 'DateUtils', 'Program', 'CareTeam', 'Practitioner'];

    function PatientTherapy($http, $resource, DateUtils, Program, CareTeam, Practitioner) {
        const resourceUrl = 'api/patient-therapies/:id';

        const service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryView': {url: 'api/patient-therapy-view', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.day1Date = DateUtils.convertLocalDateFromServer(data.day1Date);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        data.stopDate = DateUtils.convertLocalDateFromServer(data.stopDate);
                    }
                    return data;
                }
            },
            'queryDistinctFacilities': {url: 'api/patient-therapies/distinct-facilities', method: 'GET', isArray: true},
            'queryDistinctTherapies': {url: 'api/patient-therapies/distinct-therapies', method: 'GET', isArray: true},
            'queryDistinctPrescribers': {
                url: 'api/patient-therapies/distinct-prescribers',
                method: 'GET',
                isArray: true
            },
            'queryDistinctAssignees': {
                url: 'api/patient-therapies/distinct-assignees',
                method: 'GET',
                isArray: true
            },
            'start': {
                url: 'api/patient-therapies/:id/start',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'startPreview': {
                url: 'api/patient-therapies/:id/start-preview',
                method: 'POST',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'shift': {
                url: 'api/patient-therapies/:id/shift',
                method: 'POST'
            },
            'pause': {
                url: 'api/patient-therapies/:id/pause',
                method: 'POST'
            },
            'resume': {
                url: 'api/patient-therapies/:id/resume',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.resumeDate = DateUtils.convertLocalDateToServer(copy.resumeDate);
                    return angular.toJson(copy);
                }
            },
            'stop': {
                url: 'api/patient-therapies/:id/stop',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'cancel': {
                url: 'api/patient-therapies/:id/cancel',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
            },
        });

        service.findAvailableCoordinators = (patientTherapy) => {
            let promise;

            return CareTeam.getMembers(patientTherapy.patientId).then(members => {
                let facilityId;
                if (isPatientFacilityInTeam(patientTherapy, members)) {
                    facilityId = patientTherapy.patient.healthFacilityId;
                }
                if (facilityId) {
                    promise = Practitioner.query({
                        'userHasAuthority': 'PATIENT_THERAPY_MANAGE',
                        'healthFacilityId.equals': facilityId,
                        size: 1000, // TODO hard-coded size
                    }).$promise;
                } else {
                    promise = Promise.resolve(members.data
                        .filter(m => ['NURSE_COORD', 'FACILITY_PHARMACIST', 'FACILITY_DOCTOR'].includes(m.role))
                        .map(m => m.entity));
                }
                return promise.then(coordinators => {
                    const results = coordinators
                        .filter(entity => isNotPractitionerWithoutAccess(patientTherapy, entity));
                    return addCurrentAssigneeIfNotInList(patientTherapy, results);
                });
            });
        };

        function isPatientFacilityInTeam(patientTherapy, members) {
            return  members.data
                .filter(m => m.entity.id === patientTherapy.patient.healthFacilityId)
                .length > 0;
        }

        function isNotPractitionerWithoutAccess(patientTherapy, entity) {
            return !['NO_USER', 'DISABLED'].includes(entity.userStatus);
        }

        function addCurrentAssigneeIfNotInList(patientTherapy, coordinators) {
            if (patientTherapy.assignee &&
                patientTherapy.assignee.id
                && !coordinators.map(c => c.id).includes(patientTherapy.assignee.id)) {
                return Practitioner.get({id: patientTherapy.assignee.id}).$promise.then(practitioner => {
                    coordinators.push(practitioner);
                    return coordinators;
                });
            } else {
                return Promise.resolve(coordinators);
            }
        }

        return service;
    }
})();
